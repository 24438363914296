import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const Login = () => {
  return (
    <div className="bg-backgroundMain flex flex-col justify-between h-[100vh] w-full">
      <div className="pt-10  ">
        <Header />
      </div>
      <div className="bg-backgroundMain flex flex-col gap-4  items-center justify-center w-full">
        <h2 className="text-2xl">Select your login</h2>
        <Link to="https://myhealth.mywellnessid.com/signin">
          <button className="lg:gradient bg-grad2 px-12 py-5 rounded-xl">
            Login to Patient Portal
          </button>
        </Link>
        <Link to="https://app.mywellnessid.com/signin">
          <button className="lg:gradient bg-grad2 px-12 py-5 rounded-xl">
            Login to Provider Portal
          </button>
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
